import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import DialogPortal from '@components/2-molecules/DialogPortal';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import { ModalDataTypes } from '@constants/modalDataTypes';
import useDialog from '@hooks/useDialog';
import Card from '@components/2-molecules/Card';
import { TOOL_MILLING_TYPES } from '@constants/tools';
import {
  ContentWrapper,
  TypeFields,
} from '@containers/Dialogs/SelectToolTypeDialog/SelectToolTypeDialog.styled';
import { DIALOG_SIZE_VARIANT_MEDIUM } from '@components/2-molecules/Dialog';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from '@constants/router';

const MODAL_ID = ModalDataTypes.SELECT_TOOL_TYPE;

export const SelectToolTypeDialog = () => {
  const intl = useIntl();
  const { hideDialog, getDialogData, showDialog } = useDialog();
  const history = useHistory();

  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const handleClick = useCallback(
    (value) => {
      if (dialogData.toolId) {
        const navigationPath = generatePath(ROUTES.TOOL, {
          toolId: dialogData.toolId,
        });
        history.push(navigationPath, { type: value });
      } else {
        showDialog(ModalDataTypes.CREATE_TOOL, {
          toolType: value,
          toolName: dialogData.toolName,
        });
      }
    },
    [history, dialogData.toolName, dialogData.toolId, showDialog],
  );

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, [handleClose]);

  return (
    <DialogPortal
      dataTestId="select-tool-type"
      dialogId={MODAL_ID}
      onClose={handleClose}
      primaryButtonLabel={''}
      size={DIALOG_SIZE_VARIANT_MEDIUM}
      secondaryButtonLabel={''}
    >
      <PageHeader
        variant={PAGE_HEADER_VARIANT_MEDIUM}
        title={intl.formatMessage({
          id: 'tools.select_tool_type_dialog.header.type.title',
          defaultMessage: 'Select a tool type',
        })}
      />

      <TypeFields>
        <PageHeader
          variant={PAGE_HEADER_VARIANT_MEDIUM}
          title={intl.formatMessage({
            id: 'tools.select_tool_type_dialog.type.category.milling.label',
            defaultMessage: 'Milling',
          })}
        />
        <ContentWrapper>
          {Object.values(TOOL_MILLING_TYPES).map((option) => (
            <Card
              key={option.title}
              onClick={() => handleClick(option.value)}
              mediaSrc={
                option.mediaSrc || '/img/workflow-examples/horizontal.png'
              }
              mediaHeight={156}
              infoRow={{
                label: option.title,
              }}
              dataTestId={`select-tool-type__card__${option.id}`}
            />
          ))}
        </ContentWrapper>
      </TypeFields>
    </DialogPortal>
  );
};

export default SelectToolTypeDialog;
