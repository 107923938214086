import { useCallback, useContext, useEffect, useRef } from 'react';
import SnackbarContext from '@contexts/SnackbarContext';
import { SNACKBAR_CONTAINER_ID } from '@components/2-molecules/SnackbarPortal';
import { uniqueId } from 'lodash';

const SNACKBAR_FADE_OUT_TRANSITION = 300;
const SNACKBAR_FADE_OUT_TIMER_DELAY = 5000;

let snackbarFadeOutTimer;

export default function useSnackbar() {
  const {
    snackbarData,
    snackbarId,
    snackbarIsOpen,
    resetSnackbar,
    setSnackbarData,
    setSnackbarPosition,
    updateSnackbarData,
    snackbarPosition,
    setSnackbarIsOpen,
    setSnackbarId,
  } = useContext(SnackbarContext);
  const snackbarIsOpenRef = useRef(false);

  const clearSnackbarFadeOutTimer = useCallback(() => {
    clearTimeout(snackbarFadeOutTimer);
  }, []);

  const hideSnackbarAsync = useCallback(
    (resolve) => {
      clearSnackbarFadeOutTimer();

      if (!snackbarIsOpenRef.current) {
        resolve();
      }

      updateSnackbarData({ show: false });

      setTimeout(() => {
        resetSnackbar();
        setTimeout(() => resolve(), SNACKBAR_FADE_OUT_TRANSITION);
      }, SNACKBAR_FADE_OUT_TRANSITION);
    },
    [clearSnackbarFadeOutTimer, resetSnackbar, updateSnackbarData],
  );

  const hideSnackbar = useCallback(() => {
    return new Promise(hideSnackbarAsync);
  }, [hideSnackbarAsync]);

  const startSnackbarFadeOutTimer = useCallback(() => {
    snackbarFadeOutTimer = setTimeout(() => {
      hideSnackbar();
    }, SNACKBAR_FADE_OUT_TIMER_DELAY);
  }, [hideSnackbar]);

  const showSnackbar = useCallback(
    async (data = {}) => {
      if (snackbarIsOpenRef.current) {
        await hideSnackbar();
      }

      const snackbarId = uniqueId('snackbar-');

      setSnackbarId(snackbarId);
      setSnackbarData(data);

      if (data?.position) {
        setSnackbarPosition(data.position);
      }

      setSnackbarPosition(true);
      setSnackbarIsOpen(true);

      const snackbarContainerElement = document.getElementById(
        SNACKBAR_CONTAINER_ID,
      );
      const snackbarContainerObserver = new MutationObserver(
        (mutationList, observer) => {
          for (const mutation of mutationList) {
            if (mutation.type === 'childList') {
              const isSnackbarMounted = document.getElementById(snackbarId);

              if (isSnackbarMounted) {
                observer.disconnect();
                updateSnackbarData({ show: true });
                startSnackbarFadeOutTimer();
              }
            }
          }
        },
      );

      if (snackbarContainerElement) {
        snackbarContainerObserver.observe(snackbarContainerElement, {
          childList: true,
          subtree: true,
        });
      }
    },
    [
      hideSnackbar,
      setSnackbarData,
      setSnackbarPosition,
      setSnackbarIsOpen,
      setSnackbarId,
      updateSnackbarData,
      startSnackbarFadeOutTimer,
    ],
  );

  useEffect(() => {
    snackbarIsOpenRef.current = snackbarIsOpen;
  }, [snackbarIsOpen]);

  return {
    hideSnackbar,
    showSnackbar,
    snackbarData,
    snackbarId,
    snackbarIsOpen,
    updateSnackbarData,
    snackbarPosition,
    startSnackbarFadeOutTimer,
    clearSnackbarFadeOutTimer,
  };
}
