import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import useApplication from '@hooks/application/useApplication';
import useFeatureFlagValue from '@hooks/featureflags/useFeatureFlagValue';
import AppLoader from '@pageLoaders/AppLoader';
import { DROP_DOWN_MENU_CONTAINER_ID } from '@components/2-molecules/DropDownMenuPortal';
import { TOOLTIP_CONTAINER_ID } from '@components/2-molecules/TooltipPortal';
import { MODALS_COINTAINER_ID } from '@components/2-molecules/ModalPortal';
import { DIALOGS_COINTAINER_ID } from '@components/2-molecules/DialogPortal';
import { PAGE_LOADER_CONTAINER_ID } from '@components/2-molecules/PageLoaderPortal';
import SnackbarPortal, {
  SNACKBAR_CONTAINER_ID,
} from '@components/2-molecules/SnackbarPortal';
import BaseLayout from '@components/1-atoms/Layouts/BaseLayout';
import PageLoader from '@components/1-atoms/PageLoader';
import NavigationBar from '@components/NavigationBar';
import { Dialogs as LegacyProjectDialos } from '@components/Workspaces/Dialogs.jsx';
import { Dialogs as LegacyPrintersDialos } from '@components/Printers/Dialogs';
import Modals from '@containers/Modals';
import PrinterLoader from '@pageLoaders/PrinterLoader';
import WorkflowLoader from '@pageLoaders/WorkflowLoader';
import Shared from '@app/pages/Shared';
import Home from '@pages/Home';
import Projects from '@pages/Projects';
import Workflows from '@pages/Workflows';
import Workflow from '@pages/Workflow';
import Files from '@pages/Files';
import Printers from '@pages/Printers';
import Printer from '@pages/Printer';
import Analytics from '@pages/Analytics';
import { ROUTES } from '@constants/router';
import {
  LIBRARY_EXAMPLES,
  SEND_WORKFLOW_RECEIVER_FLOW_FEATURE,
  TOOL_LIBRARY_FEATURE,
} from '@constants/featureFlagConstants';
import {
  TopLayerElements,
  BottomLayerElements,
} from './AuthenticatedRoutes.styled';
import Users from '@pages/Users';
import { Materials } from '@pages/Materials/Materials';
import Material from '@pages/Material';
import MaterialLoader from '@pageLoaders/MaterialLoader/MaterialLoader';
import Dialogs from '@containers/Dialogs';
import NotFound from '@pages/NotFound';
import { useSelector } from 'react-redux';
import { getHasAdminRights } from '@selectors/loginSelectors';
import Settings from '@pages/Settings';
import { getBanners } from '@selectors/applicationSelectors';
import Library from '@pages/Library';
import Tools from '@pages/Tools';
import Tool from '@pages/Tool';
import ToolLoader from '@pageLoaders/ToolLoader';
import useFeatureFlags from '@hooks/featureflags/useFeatureFlags';

export const BOTTOM_LAYER_ELEMENTS_CONTAINER_ID = 'bottom-layer-elements';

const SettingsRoutes = () => {
  const adminRights = useSelector(getHasAdminRights());
  return (
    <Switch>
      <Route path={ROUTES.SETTINGS_ACCOUNT} component={Settings} exact />
      <Route path={ROUTES.SETTINGS_PREFERENCES} component={Settings} exact />
      {adminRights && (
        <Route path={ROUTES.SETTINGS_USERS} component={Users} exact />
      )}
      <Redirect to={ROUTES.SETTINGS_ACCOUNT} />
    </Switch>
  );
};

export default function AuthenticatedRoutes() {
  const { getIsApplicationLoading } = useApplication();
  const isApplicationLoading = getIsApplicationLoading();
  const banners = useSelector(getBanners);
  const isWorkflowReceiverFlowEnabled = useFeatureFlagValue(
    SEND_WORKFLOW_RECEIVER_FLOW_FEATURE,
  );
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const isLibraryExamplesEnabled = isFeatureFlagEnabled(LIBRARY_EXAMPLES);
  const isToolLibraryEnabled = isFeatureFlagEnabled(TOOL_LIBRARY_FEATURE);

  return (
    <>
      <AppLoader />

      <Switch>
        <Route path={ROUTES.NOT_FOUND} exact>
          <NotFound />
        </Route>

        {isWorkflowReceiverFlowEnabled && (
          <Route path={ROUTES.SHARED}>
            <Shared />
          </Route>
        )}

        <Route
          path={[
            ROUTES.HOME,
            ROUTES.PROJECTS,
            ROUTES.WORKFLOWS,
            ROUTES.FILES,
            ROUTES.PRINTERS,
            ROUTES.TOOLS,
            ROUTES.MATERIALS,
            ROUTES.MATERIAL,
            ROUTES.LIBRARY,
            ROUTES.SETTINGS,
            ROUTES.SETTINGS_USERS,
            ROUTES.SETTINGS_PREFERENCES,
            ROUTES.SETTINGS_ACCOUNT,
            ROUTES.ANALYTICS,
          ]}
          exact
        >
          <BaseLayout banners={banners}>
            <NavigationBar />

            <Switch>
              <Route path={ROUTES.HOME} component={Home} exact />

              <Route path={ROUTES.PROJECTS} component={Projects} exact />

              <Route path={ROUTES.WORKFLOWS} component={Workflows} exact />

              <Route path={ROUTES.FILES} component={Files} exact />

              <Route path={ROUTES.PRINTERS} component={Printers} exact />

              <Route path={ROUTES.SETTINGS} component={SettingsRoutes} />

              <Route path={ROUTES.MATERIALS} component={Materials} exact />

              {isLibraryExamplesEnabled && (
                <Route path={ROUTES.LIBRARY} component={Library} exact />
              )}
              {isToolLibraryEnabled && (
                <Route path={ROUTES.TOOLS} component={Tools} exact />
              )}

              <Route path={ROUTES.MATERIAL} exact>
                <MaterialLoader>
                  <Material />
                </MaterialLoader>
              </Route>

              <Route
                path={`${ROUTES.ANALYTICS}/:dashboardId?`}
                component={Analytics}
              />
            </Switch>
          </BaseLayout>
        </Route>

        <Route path={ROUTES.TOOL} exact>
          <ToolLoader>
            <Tool />
          </ToolLoader>
        </Route>

        <Route path={ROUTES.PRINTER} exact>
          <PrinterLoader>
            <Printer />
          </PrinterLoader>
        </Route>

        <Route path={ROUTES.WORKFLOW} exact>
          <WorkflowLoader>
            <Workflow />
          </WorkflowLoader>
        </Route>

        <Redirect to={ROUTES.HOME} />
      </Switch>

      <Route path={Object.values(ROUTES)} exact>
        <Modals />
        <Dialogs />
        <LegacyProjectDialos />
        <LegacyPrintersDialos />
        <SnackbarPortal />
      </Route>

      <BottomLayerElements id={BOTTOM_LAYER_ELEMENTS_CONTAINER_ID} />

      <TopLayerElements>
        <div id={MODALS_COINTAINER_ID} />
        <div id={DIALOGS_COINTAINER_ID} />
        <div id={DROP_DOWN_MENU_CONTAINER_ID} />
        <div id={TOOLTIP_CONTAINER_ID} />
        <div id={SNACKBAR_CONTAINER_ID} />
        <div id={PAGE_LOADER_CONTAINER_ID}>
          <PageLoader show={isApplicationLoading} />
        </div>
      </TopLayerElements>
    </>
  );
}
